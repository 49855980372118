.carousel-caption {
    position: relative;
    left: auto;
    right: auto;
}

@media (max-width: 900px) {
    .carousel {
      width:auto;
      height:auto;
      background-color: bg-dark;
    }
}