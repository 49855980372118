.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.Navbar {
  align-items: center;
  justify-content: center;
  width:100%;
  display: flex;
}


.App-header {
  background-color: white;  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width:100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  overflow-x: auto;
}

@media screen and (max-width: 780px) {
    .sounds_learned_text {
        display: none !important;
    }
    .difficulty_buttons{
      padding: 0;
    }

    width: 75%
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}