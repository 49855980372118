html,body {
  background-color: white;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  max-width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

button {
	background: transparent; 
	border-radius: 10px;
	padding: 6px 12px;
	display: inline-block;
}

button .btn {
	margin-bottom: 5px;
}

button:hover {
  background-color: #F1ECEB ;
  color:#f00;
}

button:active {
  color:#f00;
}

list-group-item:hover {
    background-color: #f5f5f5;
}
